import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import clsx from "clsx";
import PropTypes from 'prop-types';
import useSources from "../hooks/sources";


const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "#000",
  },
  item: {
    backgroundColor: "#fff",
    color: "#000",
  },
  selectitem: {
    backgroundColor: "#2684FF",
    color: "#fff",
  },
});






export default function BasicMenu({ anchorEl, open, handleClose }) {
  const { sourceId } = useParams();
  const { sourcesList } = useSources();

  let sourcesIdsList = [];

  if (sourcesList) {
    sourcesIdsList = Object.keys(sourcesList)
  }




  const classes = useStyles();
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {
          sourcesIdsList.map((id) => (
            <Link to={`/workspace/${id}/1/1/1`} className={classes.link} key={id}>
              <MenuItem
                className={clsx(classes.item, {
                  [classes.selectitem]: sourceId === id,
                })}
                onClick={handleClose}
              >
                {`${sourcesList[Number(id)].label1} ${sourcesList[Number(id)].label2}`}
              </MenuItem>
            </Link>
          ))
        }
      </Menu>
    </div>
  );
}

BasicMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ node: PropTypes.instanceOf(Element) })
  ]),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};