export const CLICK_NEXT_BUTTON = 'CLICK_NEXT_BUTTON';
export const CLICK_PREVIOUS_BUTTON = 'CLICK_PREVIOUS_BUTTON';
export const CLICK_VALIDATION_BUTTON = 'CLICK_VALIDATION_BUTTON';
export const CLICK_REDO_BUTTON = 'CLICK_REDO_BUTTON';
export const OPEN_SYNTHESIS_MODAL = 'OPEN_SYNTHESIS_MODAL';
export const CLOSE_SYNTHESIS_MODAL = 'CLOSE_SYNTHESIS_MODAL';
export const CLICK_COURSE_LINK = 'CLICK_COURSE_LINK';
export const CHECK_PROPOSITION = 'CHECK_PROPOSITION';
export const UNCHECK_PROPOSITION = 'UNCHECK_PROPOSITION';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const SELECT_QUESTION = 'SELECT_QUESTION';